<template>
  <VCard
    :class="[
      'reports-list-item',
      'report-item ',
      { invalid: invalid },
      `status-${report.REPORT_STATUS}`,
    ]"
  >
    <RouterLink :to="routerLink">
      <h2 class="display-1 date">{{
        report.REPORT_DATE ? report.REPORT_DATE.date_formatted : '-'
      }}</h2>
      <div v-if="!$route.params.loc_id && !invalid" class="detail-group"
        >{{ report.JOB_NUMBER }} - {{ report.LOCATION_IDENTIFIER }}</div
      >
      <div class="detail-group">
        <div class="detail-label">Foreman</div>
        <div class="detail">{{ report.FOREMANS_NAME }}</div>
      </div>
      <div class="detail-group">
        <div class="detail-label">Status</div>
        <div class="detail">{{ report.REPORT_STATUS }}</div>
      </div>
    </RouterLink>
  </VCard>
</template>

<script>
export default {
  name: 'ReportsListItem',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    invalid() {
      return typeof this.report.LOCATION === 'undefined'
    },
    routerLink() {
      if (typeof this.report.LOCATION === 'object') {
        return `/locations/${this.report.LOCATION[0] &&
          this.report.LOCATION[0].id}/dailys/${this.report.ID}`
      }
      return ''
    }, // routerLink
  },
}
</script>

<style lang="scss" scoped>
.reports-list-item {
  display: block;
  padding: $gutter;
  border-top: 12px solid gray;
  &.status-Setup {
    border-top-color: $orange;
  }
  &.status-Completed {
    border-top-color: $green;
  }
  &.status-Locked {
    border-top-color: $blue;
  }
  &.status-Scheduled {
    border-top-color: $teal;
  }
  &.invalid {
    border-top-color: $red;
  }
  a {
    color: $gray-dark;
    .date {
      margin-bottom: 5px;
    }
    .detail-group {
      margin-bottom: 0.7em;
    }
    .detail-label {
      margin-bottom: 0;
      font-size: 0.8rem;
      color: $gray-body;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .detail {
      font-size: 1.1rem;
      color: $gray-dark;
    }
  }
}
</style>

<template>
  <VContainer grid-list-xl fluid>
    <slot>
      <VLayout row wrap>
        <template v-if="reports && reports.length">
          <VFlex v-for="report in reports" :key="report.ID" xs12 sm6 md4>
            <ReportsListItem :report="report" />
          </VFlex>
        </template>
        <VFlex v-else
          ><h3>
            No reports found
          </h3></VFlex
        >
      </VLayout>
    </slot>
  </VContainer>
</template>

<script>
import ReportsListItem from '@components/ReportsListView/ReportsListItem'
export default {
  name: 'ReportsList',
  components: { ReportsListItem },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
